import React, {useEffect, useState} from 'react'
import "./Home.css"
import { Typography, Avatar, Button, Modal, Input } from 'antd';
import { MessageOutlined, BellOutlined, PlusOutlined } from '@ant-design/icons';
import Post from '../Post/Post';


const Home = (props) => {
	const [posts, setPosts] = useState([])
	const [open, setOpen] = useState(false)
	const [loading, setLoading] = useState(false);
	const [posting, setPosting] = useState(false)
	const [username, setUsername] = useState("");
	const [title, setTitle] = useState("");
	const [content, setContent] = useState("");
	
	useEffect(() => {
		getPosts();
	}, [])
	
	const getPosts = () => {
		fetch('https://backend.pndjay.workers.dev/posts')
			.then(response => response.json())
			.then(data => setPosts(data.reverse()))
	}
	
	const createPost = () => {
		if (username==="" || title==="" || content==="") {
			alert("All fields are required");
			return;
		}
		setLoading(true);
		var xhr = new XMLHttpRequest();
		xhr.open("POST", "https://backend.pndjay.workers.dev/posts", true);
		xhr.setRequestHeader('Content-Type', 'application/json');
		xhr.setRequestHeader("Accept", "application/json");
		xhr.onreadystatechange = () => {
			if (xhr.readyState === 4) {
				if (xhr.status === 201) {
						setLoading(false);
						setOpen(false);
						setPosting(true)
						setTimeout(() => {
							getPosts();
							setPosting(false);
						}, 3000)
				} else {
						setLoading(false);
						setUsername("");
						setTitle("");
						setContent("");
				}
			}
		};
		xhr.send(JSON.stringify({
			username,
			title,
			content
		}));
		
	}
	
	return (
		<div className="Home">
			<div className="header">
				<Typography.Title level={3}>Social App</Typography.Title>
				<div style={{display: "flex", justifyContent: "center", alignItems: "center", marginTop: 3}}>
					<Button shape="circle" icon={<MessageOutlined style={{fontSize: 20}} />} size="large" style={{marginRight: 35}} />
					<Button shape="circle" icon={<BellOutlined style={{fontSize: 20}} />} size="large" style={{marginRight: 38}} />
					<Avatar className="avatar" size={39} src="https://picsum.photos/200?random=1" />
				</div>
			</div>
			<Button onClick={() => setOpen(true)} loading={posting} shape="round" className="create-post" icon={<PlusOutlined style={{marginBottom: -2}} />} size="large">
				Create Post
			</Button>
			<div className="posts">
				{posts.map((post, index) => <Post key={index} data={post}/>)}
			</div>
			<Modal
				className="dialog"
				title="Create Post"
				closable={false}
				visible={open}
				footer={[
					<Button className="dialog-button" key="cancel" shape="round" onClick={() => setOpen(!open)}>
					  Cancel
					</Button>,
					<Button
						className="dialog-button"
					  	key="create"
					  	type="primary"
					  	shape="round"
						loading={loading}
					  	onClick={() => createPost()}
					>
					  Create
					</Button>
				  ]}
			>
				<Input placeholder="Username" value={username} style={{marginBottom: 20}} onChange={(e) => setUsername(e.target.value)} />
				<Input placeholder="Title" value={title} style={{marginBottom: 20}} onChange={(e) => setTitle(e.target.value)}/>
				<Input placeholder="Content" value={content} style={{marginBottom: 10}} onChange={(e) => setContent(e.target.value)}/>
			</Modal>
		</div>
	)
}

export default Home
