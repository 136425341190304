import React from 'react'
import { Avatar, Typography, Image } from 'antd';
import "./Post.css"

const Post = (props) => {
	return (
		<div className="post">
			<div style={{display: "flex", flexDirection: "row", alignItems: "center", marginBottom: 12}}>
				<Avatar className="avatar" src={"https://picsum.photos/200?random="+props.data.id} size={50}/>
				<div>
					<Typography.Title level={5} style={{margin: 0, marginLeft: 15, marginBottom: -6}}>{props.data.username}</Typography.Title>
					<Typography.Text level={3} style={{margin: 0, marginLeft: 15, marginTop: -4, fontSize: 13}}>{props.data.title}</Typography.Text>
				</div>
			</div>
			<Typography.Paragraph style={{marginLeft: 5}}>{props.data.content}</Typography.Paragraph>
			<Image className="photo" src={"https://picsum.photos/600?random="+props.data.id} size={50}/>
		</div>
	)
}

export default Post
